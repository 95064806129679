import { motion } from 'framer-motion'
import React from 'react'

export const NeunSvg = ({color, size}) => {
    const width =  size * 155
    const height =  size * 408
    return (
        <div>
            <svg width={width} height={height} viewBox="0 0 155 408" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M94.8656 134.811H0.705566V0H154.351V19.2587H20.3216V115.552H101.503L134.692 19.2587H154.351L20.3216 408H0.705566L94.8656 134.811Z" fill={color}/>
            </svg>
        </div>
    )
}
export const VierSvg = ({color, size}) => {
    const width =  size * 169
    const height =  size * 415
    return (
        <div>
            <svg width={width} height={height} viewBox="0 0 169 415" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M114.372 415V350.916H0.249023V331.305L2.17252 325.721L93.3513 60.7507L114.372 0H134.392V331.305H168.668V350.916H134.392V415H114.372ZM21.2698 331.305H114.372V60.7507L21.2698 331.305Z" fill={color}/>
            </svg>
        </div>
    )
}
export const LinienSvg = ({color, size}) => {
    const width =  size * 270
    const height =  size * 615
    return (
        <div>
            <svg width={width} height={height} viewBox="0 0 270 615" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M52.7564 612.305L264.134 0.000219196" stroke={color} strokeWidth="12"/>
                <path d="M6.57277 612.305L217.951 0.000219216" stroke={color} strokeWidth="12"/>
            </svg>
        </div>
    )
}
export const MenuRight = ({color, size}) => {
    const width =  size * 365
    const height =  size * 241
    return (
        <div>
            <svg width={width} height={height} viewBox="0 0 365 241" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 0V241" stroke={color} strokeWidth="5"/>
                <path d="M67.376 241L41.312 124.36H56.576L75.008 210.328L92.576 124.36H107.408L81.632 241H67.376ZM150.879 241V124.36H166.863V241H150.879ZM215.974 241V124.36H261.19V136.456H232.246V175.048H255.718V186.568H232.246V229.336H261.478V241H215.974ZM304.855 241V124.36H328.327C336.103 124.36 342.535 125.416 347.623 127.528C352.711 129.544 356.455 132.856 358.855 137.464C361.351 141.976 362.599 147.928 362.599 155.32C362.599 159.832 362.119 163.96 361.159 167.704C360.199 171.352 358.663 174.472 356.551 177.064C354.439 179.56 351.655 181.384 348.199 182.536L364.759 241H349.063L333.799 186.28H321.127V241H304.855ZM321.127 174.616H327.319C331.927 174.616 335.671 174.04 338.551 172.888C341.431 171.736 343.543 169.768 344.887 166.984C346.231 164.2 346.903 160.312 346.903 155.32C346.903 148.504 345.655 143.608 343.159 140.632C340.663 137.56 335.719 136.024 328.327 136.024H321.127V174.616Z" fill={color}/>
            </svg>
        </div>
    )
}
export const MenuLeft = ({color, size}) => {
    const width =  size * 395
    const height =  size * 241
    return (
        <div>
            <svg width={width} height={height} viewBox="0 0 395 241" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M392 0V241" stroke={color} strokeWidth="5"/>
                <path d="M0.160249 118V1.35999H8.08025L45.2322 91.936V1.35999H54.7363V118H46.9603L9.66425 26.416V118H0.160249ZM105.069 118V1.35999H147.261V9.85599H116.157V53.632H141.501V61.696H116.157V109.792H147.549V118H105.069ZM221.34 119.44C213.468 119.44 207.324 118.048 202.908 115.264C198.588 112.384 195.612 108.448 193.98 103.456C192.348 98.368 191.532 92.608 191.532 86.176V1.35999H202.188V86.464C202.188 90.976 202.668 95.104 203.628 98.848C204.684 102.496 206.604 105.376 209.388 107.488C212.172 109.6 216.156 110.656 221.34 110.656C226.332 110.656 230.22 109.6 233.004 107.488C235.788 105.28 237.708 102.352 238.764 98.704C239.916 95.056 240.492 90.976 240.492 86.464V1.35999H251.004V86.176C251.004 92.608 250.14 98.368 248.412 103.456C246.684 108.448 243.66 112.384 239.34 115.264C235.02 118.048 229.02 119.44 221.34 119.44ZM300.412 118V1.35999H308.332L345.484 91.936V1.35999H354.988V118H347.212L309.916 26.416V118H300.412Z" fill={color}/>
            </svg>
        </div>
    )
}
export const Circle = ({color, size}) => {
    const width =  size * 697
    const height =  size * 697
    return (
        <div>
            <svg width={width} height={height} viewBox="0 0 697 697" fill="none" xmlns="http://www.w3.org/2000/svg">
                <motion.path 
                d="M696.5 348.5C696.5 540.695 540.695 696.5 348.5 696.5C156.305 696.5 0.5 540.695 0.5 348.5C0.5 156.305 156.305 0.5 348.5 0.5C540.695 0.5 696.5 156.305 696.5 348.5Z" 
                stroke={color}
                strokeWidth="3"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                exit={{ pathLength: 0 }}
                transition={{ duration: 1 }}
                
                />
            
            </svg>

        </div>
    )
}
export const CircleSvgStart = ({color, size}) => {
    const width =  size * 697
    const height =  size * 697
    return (
        <div>
            <svg width={width} height={height} viewBox="0 0 697 697" fill="none" xmlns="http://www.w3.org/2000/svg">
                <motion.path 
                d="M696.5 348.5C696.5 540.695 540.695 696.5 348.5 696.5C156.305 696.5 0.5 540.695 0.5 348.5C0.5 156.305 156.305 0.5 348.5 0.5C540.695 0.5 696.5 156.305 696.5 348.5Z" 
                stroke={color}
                strokeWidth="3"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                exit={{pathLength: 0}}
                transition={{ duration: 1 }}
                
                />
            
            </svg>

        </div>
    )
}

export const Envelope = ({color, size}) => {
    const width =  size * 499
    const height =  size * 172
    return (
        <div>
            <svg width={width} height={height} viewBox="0 0 499 275" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.3626 2.65116L495.519 19.6704L486.621 272.261L3.46453 255.242L12.3626 2.65116Z" fill="transparent" stroke={color} strokeWidth="6"/>
                <path d="M15.9491 2.42976L247.829 175.694L491.327 19.1749L15.9491 2.42976Z" fill="transparent" stroke={color} strokeWidth="6"/>
            </svg>

        </div>
    )
}

export const NeunOderVierSvg = ({color, size}) => {
    const width =  size * 321
    const height =  size * 297
    return (
        <div>
            <svg width={width} height={height} viewBox="0 0 321 297" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M269.089 68.7244H67.9629V272.782H269.089V68.7244Z" stroke="#2B3036" strokeWidth="2" strokeMiterlimit="1.5" strokeLinecap="round"/>
                <path d="M98.739 211.663L137.157 96.1368L91.7747 96.1872L91.7158 86.5224L148.308 86.168L148.561 95.8317L109.897 211.364L98.739 211.663Z" fill="#2B3036"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M99.6667 86.4453L91.7139 86.4949L91.9763 129.675L99.9292 129.624L99.6667 86.4453Z" fill="#2B3036"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M134.541 129.445L134.492 121.376L91.9326 121.643L91.9815 129.712L134.541 129.445Z" fill="#2B3036"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M198.774 54.7358H194.988L114.922 282.019H118.706L198.774 54.7358Z" fill="#2B3036"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M208.897 54.7358H205.065L124.995 282.077H128.827L208.897 54.7358Z" fill="#2B3036"/>
                <path d="M218.228 247.734V231.266L185.203 231.666V221.464L218.228 119.044H228.678V221.464H242.864V231.266H228.678V247.734H218.228ZM196.245 221.864H216.651L218.773 146.329L196.245 221.864Z" fill="#2B3036"/>
            </svg>
        </div>
    )

}
export const ArrowDown = ({color, size}) => {
    const width =  size * 70
    const height =  size * 36
    return (
        <div css={{transform: "rotate(-90deg)"}}>
            <svg width={width} height={height} viewBox="0 0 70 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line css={{transition: "stroke 0.4s ease"}} x1="0.353553" y1="0.646447" x2="35.3536" y2="35.6464" stroke={color} stroke-width="3"/>
            <line css={{transition: "stroke 0.4s ease"}} x1="34.6464" y1="35.6464" x2="69.6464" y2="0.646441" stroke={color} stroke-width="3"/>
            </svg>
        </div>
    )

}

